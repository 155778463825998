@use "./variables.scss" as v;
@use "./mixins.scss" as m;

// LIGHT THEME VARIABLES
html{
    --bg-color: #{v.$white};
    --text-color: #{v.$black};
    --highlight-primary-color: #{v.$black};
    --highlight-secondary-color: #{v.$orange};
    --mode-toggle-color: invert(0%) sepia(100%) saturate(7500%) hue-rotate(257deg) brightness(94%) contrast(106%);
    --mode-toggle-direction: rotate(180deg);
}
// DARK THEME VARIABLES
html[data-theme='dark'] {
    --bg-color: #{v.$black};
    --text-color: #{v.$white};
    --highlight-primary-color: #{v.$orange};
    --highlight-secondary-color: #{v.$yellow};
    --mode-toggle-color: invert(78%) sepia(13%) saturate(7458%) hue-rotate(336deg) brightness(108%) contrast(97%);
    --mode-toggle-direction: rotate(0);
}

// MAIN STYLES
html {
    width: 100%;
    height: 100vh;
}
body {
    font-family: v.$open;
    color: var(--text-color);
    background-color: var(--bg-color);
    transition: all .1s ease-out;
}
a {
    color: var(--text-color);
    &:hover {
        color: var(--highlight-secondary-color);
    }
}

/************
buttons
*************/

.btn {
    display: inline-block;
    min-width: 150px;
    background: transparent;
    color: var(--text-color);
    border: 2px solid v.$orange;
    border-radius: 5px;
    padding: 5px 15px;
    text-decoration: none;
    &:hover {
        background-color: rgba(v.$orange, .5);
        color: var(--text-color);
    }
}

#root {
    position: relative;
    &:before {
        content: "";
        position: fixed;
        top: 75px;
        left: 70px;
        display: block;
        height: 560px;
        width: 435px;
        background: url("../assets/img/head.png") no-repeat 0 0;
        background-size: contain;
        opacity: .3;
    }
    @include m.for-size(phone-only) {
        &:before {
            top: 80px;
            left: 55px;
            height: 330px;
            width: 260px
        }
    }
}