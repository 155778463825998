@use "./variables.scss" as v;
@use "./theme.scss" as t;

.nav {
    &__mode-toggler.btn {
        position: relative;
        min-width: 0;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        img {
            width: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(1) var(--mode-toggle-direction);
            filter: var(--mode-toggle-color);
        }
    }
}