@use "./mixins.scss" as m;
@use "./theme.scss" as t;
@use "./variables.scss" as v;

.top {
    display: flex;
    justify-content: center;
    align-items: center;
    > * {
        width: 50%;
    }
}
.header {
    $self: &;
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 115px;
    padding: 0 15px;
    background-color: rgba($color: var(--bg-color), $alpha: .85);
    -webkit-backdrop-filter: blur(1px);
    backdrop-filter: blur(1px);
    transition-property: all; 
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
    &.header--hide {
        background-color: transparent;
        #{ $self }__title {
            font-size: 2rem;
            display: flex;
            align-items: center;
            &:before {
                content: '';
                display: inline-block;
                height: 60px;
                width: 60px;
                background: url("../assets/img/logo.svg") no-repeat 0 0;
                background-size: 100%;
            }
        }
        #{ $self }__span--vertical {
            position: relative;
            left: auto;
            top: auto;
            transform: rotate(0);
            margin-right: 3px;
        }
        #{ $self }__logo {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    &__title {
        position: relative;
        margin: 0;
        font-family: v.$kanit;
        @include m.fs(150);
        font-weight: 900;
        line-height: .8;
        letter-spacing: .01rem;
        color: var(--text-color);
        opacity: 1;
        transition: all .1s ease-out;
    }
    &__span--vertical {
        position: absolute;
        transform-origin: 0 0;
        transform: rotate(-90deg) translate(-137%, 0%);
        transition: all .1s ease-out;
    }
    @include m.for-size(phone-only) {
        &__title {
            @include m.fs(80);
        }
    }

}