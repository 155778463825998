/// Mixin to manage responsive breakpoints
@mixin for-size($size) {
  @if $size == phone-only {
      @media (max-width: 599px) { @content; }
  } @else if $size == tablet-portrait-down {
      @media (max-width: 600px) { @content; }
  } @else if $size == tablet-portrait-up {
      @media (min-width: 600px) { @content; }
  } @else if $size == tablet-landscape-up {
      @media (min-width: 900px) { @content; }
  } @else if $size == desktop-up {
      @media (min-width: 1200px) { @content; }
  } @else if $size == big-desktop-up {
      @media (min-width: 1800px) { @content; }
  }
}

// fonts
@mixin fs($sizeValue: 16) {
  font-size: calc($sizeValue / 10) + rem;
  font-size: $sizeValue + px;
}